









































import Vue from "vue";
import { Action, Getter } from "vuex-class";
import { validationMixin } from "vuelidate";
import { EmailsInput } from "@/components/common";
import { Component, Watch } from "vue-property-decorator";
import { required, minLength } from "vuelidate/lib/validators";
@Component({
  mixins: [validationMixin],
  components: { EmailsInput },
  validations: {
    companyName: { required, minLength: minLength(3) },
  },
})
export default class CreateCompanyForm extends Vue {
  @Getter("company/creating") loading!: boolean;
  @Action("company/create") createCompany!: Function;
  @Action("displaySnackbar") displaySnackbar!: Function;

  vatId = "";
  companyName = "";
  get nameErrors(): any[] {
    const errors: any[] = [];
    if (!this.$v.companyName!.$dirty) return errors;
    !this.$v.companyName!.required && errors.push("Required Field");
    !this.$v.companyName!.minLength &&
      errors.push("Must be at least 3 characters");
    return errors;
  }

  async createConfirm() {
    if (this.loading) return;
    this.$v.$touch();
    if (this.$v.$invalid) {
      const str = this.$t("forms.invaliddata").toString();
      this.displaySnackbar(str);
      return;
    }

    const data = {
      name: this.companyName,
      vat: this.vatId,
    };
    this.createCompany(data);
  }
  createCancel() {
    // @ts-ignore
    this.$refs.form.reset();
  }

  @Watch("createCompanyLoading")
  nextStep() {
    return;
  }
}
