import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VForm } from 'vuetify/lib/components/VForm';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VForm,{ref:"form",on:{"submit":function($event){$event.preventDefault();return _vm.createConfirm($event)}}},[_c('div',{staticClass:"text-h3 font-weight-bold mb-12",staticStyle:{"font-size":"30px"}},[_vm._v(" "+_vm._s(_vm.$t("company.createbtn"))+" ")]),_c(VCard,[_c(VCardText,[_c(VTextField,{staticClass:"mb-4",attrs:{"label":_vm.$t('company.name'),"error-messages":_vm.nameErrors},model:{value:(_vm.companyName),callback:function ($$v) {_vm.companyName=$$v},expression:"companyName"}})],1),_c(VDivider,{staticClass:"my-4"}),_c(VCardActions,[_c(VSpacer),_c(VBtn,{attrs:{"elevation":"0","color":"accent"},on:{"click":_vm.createCancel}},[_c('span',{staticStyle:{"color":"black"}},[_vm._v(_vm._s(_vm.$t("global.reset")))])]),_c(VBtn,{attrs:{"elevation":"0","color":"primary","loading":_vm.loading,"disabled":_vm.loading},on:{"click":_vm.createConfirm}},[_c('span',[_vm._v(_vm._s(_vm.$t("global.create")))])])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }